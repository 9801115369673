.container-login {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(to right,
            #E7A78F 0%,
            #E7A78F 50%,
            #FFF4F0 50%,
            #FFF4F0 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-login {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.0025em;
    color: #F05423;
    margin-left: 110px;
}

.img-login {
    width: 700.24px;
    height: 450.13px;
    top: 293px;
    object-fit: cover;
    object-position: right;
}

.btn-login:hover {
    background-color: yellow;
}

.btn-login {
    width: 60px;
    height: 48px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-transform: uppercase;

    color: #FFF4F0;
}

.input-login{
    background: #FBEAE2;
    border-radius: 8px;
    padding-right: 130px;
}