body {
    background-color: #d2d6de;
    font-family: 'Poppins';
}

.box-title {
    margin-top: 5px !important;
    font-weight: normal;
}

.mr-1 {
    margin-right: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-15 {
    margin-top: 1.5rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mr-2 {
    margin-right: 2rem;
}

.m-2 {
    margin: 2rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.m-3 {
    margin: 3rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.p-0 {
    padding: 0 !important;
}

.error-page {
    margin-top: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.error-page h3 {
    margin-top: 0px;
}

.btn-box-tool {
    color: #444;
    padding: 6px 12px;
    /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px; */
}

.hr-muted {
    border-color: #b4b4b42c;
}

.box-shift {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.box-shift ul {
    margin-top: 5px;
    padding-right: 5px;
}

.box-shift ul li {
    text-align: right;
}

.box-shift-name {
    display: inline-block;
    padding: 5px;
    margin-top: 5px;
}

.box-shift-time {
    font-size: 12px;
    margin-bottom: 5px;
}

.date-header-shift {
    width: 100px !important;
}

.date-header {
    width: 150px !important;
}

.date-header .day,
.date-header .month {
    margin-left: 5px;
}

.date-header .day {
    margin-top: 3px;
}

.text-white {
    color: white !important;
}

.calendar .section-unit {
    width: 120px;
}
.calendar .section-shift {
    width: 90px;
}

.v-center {
    vertical-align: middle !important;
}

.text-left {
    text-align: left !important;
}

.h2-custom {
    padding: 10px 0px 20px;
    margin: 0 !important;
    font-weight: normal;
    font-family: 'Poppins';
}

.logo-loading {
    padding-top: 100px;
    padding-bottom: 100px;
}

.shift {
    margin: 10px 10px 25px 10px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    box-shadow: none;
}
.shift-start {
    border: 5px solid #316dad;
    background-color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    color: #316dad;
}
.shift-finish {
    border: 5px solid #316dad;
    background-color: #316dad;
    font-size: 24px;
    text-transform: uppercase;
    color: #fff;
}

.callout.callout-danger {
    background-color: #f0685e !important;
    border-color: transparent;
    border-left: 3px solid #cf1c0f !important;
    color: #fff;
    border-radius: 0px;
}

.callout h4 {
    font-size: 16px;
    margin-bottom: 5px;
}

.mb-0 {
    margin-bottom: 0px;
}

.box-clear {
    background: no-repeat;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
}

.box-clear .box-header {
    padding: 0;
}

.box-clear .box-tools {
    right: -10px;
    top: -4px;
    position: relative;
}

.box-clear .box-body {
    padding: 0;
}

.box-clear .btn-shift {
    padding: 5px !important;
    display: block;
}

.box-clear .small-box {
    max-width: 150px;
    margin-bottom: 5px;
}

.box-clear .small-box .inner h3 {
    font-size: 26px;
    margin-bottom: 5px;
}

.box-clear .small-box .inner p {
    margin-bottom: 0px;
}
.box-clear .small-box > .small-box-footer {
    padding: 3px 0 !important;
}

.wrapper {
    background-color: #ecf0f5 !important;
}

.ml-half {
    margin-left: 0.5rem;
}

.mb-half {
    margin-bottom: 0.5rem;
}

.right .direct-chat-text {
    margin-right: 0px;
}

.right .direct-chat-text:after,
.right .direct-chat-text:before {
    display: none;
}

.iframe {
    width: 100%;
    height: 500px;
    border: 0px;
}

/* aside.main-sidebar {
    overflow-y: auto;
    min-height: auto;
    max-height: 100%;
} */

/* .skin-blue .main-header .navbar .nav > li > button {
    color: #fff;
    background-color: transparent;
    border: 0px;
}
@media (min-width: 768px) {
    .navbar-nav > li > button {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
.navbar-nav > li > button {
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: 20px;
}
.nav > li > button {
    position: relative;
    display: block;
    padding: 13px 15px;
}
.main-header .navbar .nav > li > button > .label {
    position: absolute;
    top: 9px;
    right: 7px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: 0.9;
}
.skin-blue .main-header .navbar .nav > li > button:hover,
.skin-blue .main-header .navbar .nav > li > button:active,
.skin-blue .main-header .navbar .nav > li > button:focus,
.skin-blue .main-header .navbar .nav .open > button,
.skin-blue .main-header .navbar .nav .open > button:hover,
.skin-blue .main-header .navbar .nav .open > button:focus,
.skin-blue .main-header .navbar .nav > .active > button {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
}

.btn-notification {
    margin-top: 2px;
}

.nav-tabs > li > button {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.nav-tabs-custom > .nav-tabs > li > button {
    color: #444;
    border-radius: 0;
}

.nav-tabs-custom > .nav-tabs > li > button,
.nav-tabs-custom > .nav-tabs > li > button:hover {
    background: transparent;
    margin: 0;
}

.nav-tabs-custom > .nav-tabs > li.active > button {
    border-top-color: transparent;
    border-left-color: #f4f4f4;
    border-right-color: #f4f4f4;
    border-bottom: 2px solid #fff;
}
.bg-login {
    background-color: #d2d6de;
    height: 100vh;
}
.login-box,
.register-box {
    margin: 0px auto;
    padding: 7% 0px;
}
.error-page {
    margin: 0px auto;
}
.mt-0 {
    margin-top: 0px;
} */

.time-slide-picker .input-range__slider-container:first-of-type {
    display: none;
}
